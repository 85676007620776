<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Visualización de documentos
            </div>

            <v-form :disabled="loading">
                <v-row class="mb-2">
                    <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                        <DateFind label="Desde" v-model="filtros.FechaDesde" @change="loadData"></DateFind>
                    </v-col>

                    <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                        <DateFind label="Hasta" v-model="filtros.FechaHasta" @change="loadData"></DateFind>
                    </v-col>

                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <PerFind class="mb-n7" ref="perCtrl" v-model="filtros.PerAux" label="Tienda/Departamento" @change="loadData"></PerFind>
                    </v-col>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.InfFic"
                            label="Documento"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" lg="3" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.InfAmbito"
                            label="Ámbito"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.InfCarpeta"
                            label="Carpeta"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            
            <v-row class="mt-0 mb-1">
              <v-col cols="12">
                <label class="mr-2">Nº VISUALIZACIONES: {{totalDataRows}}</label>
                <label>(Internas: {{extraData.TotalCountInt}},  Externas: {{extraData.TotalCountExt}})</label>
                <label> / </label>
                <label class="mr-2">Nº EMPLEADOS: {{extraData.PerCount}}</label>
                <label>(Internas: {{extraData.PerCountInt}},  Externas: {{extraData.PerCountExt}})</label>
              </v-col>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.InfVisTmp="{ item }">
                    {{ formatDate(item.InfVisTmp) }}
                </template>
            </v-data-table>

            <v-row class="mt-3 mb-4" justify="center">
              <v-btn
              :loading="loading || downloading"
              class="mr-2"
              @click="onBtnDownloadReport('PDF')">
                Exportar
                <v-icon color="red" right size="25">
                    mdi-file-pdf-box
                </v-icon>
              </v-btn>
              <v-btn
              :loading="loading || downloading"
              @click="onBtnDownloadReport('EXCELOPENXML')">
                Exportar
                <v-icon color="green" right size="25">
                    mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </v-row>
            
            <v-container class="chart-container">
                <GraficoLineas
                ref="lineChart"
                :chart-data="chartData" 
                :options="chartOptions">
                </GraficoLineas>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import DateFind from "../components/DateFind.vue";
import PerFind from "../components/PerFind.vue";
import { mapState } from "vuex";
import GraficoLineas from "../components/EvolucionAnualGrafico.js";
export default ({
    components: {
        DateFind, PerFind, GraficoLineas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            downloading: false,
            extraData: {
              PerCount: 0,
              PerCountInt: 0,
              PerCountExt: 0,
              TotalCountInt: 0,
              TotalCountExt: 0,
              TotalByDay: [],
              ChartType: ''
            },
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width: 145, sortable: false, value: 'InfVisTmp' },
                { text: 'Visualizado por', width:250, sortable: false, value: 'Per.PerNom' },
                { text: 'Tienda/Departamento', width:250, sortable: false, value: 'PerAux.PerNom' },
                { text: 'Documento', width:200, sortable: false, value: 'InfFic' },
                { text: 'Ámbito', width:100, sortable: false, value: 'InfAmbito' },
                { text: 'Carpeta', width:200, sortable: false, value: 'InfCarpeta' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                InfFic: '',
                FechaDesde: null,
                FechaHasta: null,
                PerAux: {},
                InfAmbito: '',
                InfCarpeta: ''
            },
            chartData: {},
            chartOptions: {
                mantainAspectRatio: true,
                aspectRatio: 2,
                scales: {
                    xAxes: [{
                        offset: false
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                                return value.toString().replace('.', ',');
                            }
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false,
                        borderWidth: 3,
                    }
                },
                tooltips: {
                    mode: 'index',
                    position: 'nearest'
                }
            },
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.perCtrl.$refs.PerFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    created() {
      window.addEventListener('resize', this.fixChartAspectRatio);
    },
    destroyed() {
      window.removeEventListener('resize', this.fixChartAspectRatio);
    },
    mounted() {
      this.fixChartAspectRatio(false);
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_INFVIS_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            EmpId: this.empId,
            FechaDesde: this.filtros.FechaDesde,
            FechaHasta: this.filtros.FechaHasta,
            PerIdAux: this.filtros.PerAux != null ? this.filtros.PerAux.PerId : null,
            InfFic: this.filtros.InfFic,
            InfAmbito: this.filtros.InfAmbito,
            InfCarpeta: this.filtros.InfCarpeta
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;
            const extraData = result.data.ExtraData

            setTimeout(() => {
                this.loading = false
                resolve({
                items,
                total,
                extraData
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar los documentos.');
            }
          });
        })
      },
      downloadReportFromApi(fileType) {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_INFVIS_REPORT',
            FileType: fileType,
            EmpId: this.empId,
            FechaDesde: this.filtros.FechaDesde,
            FechaHasta: this.filtros.FechaHasta,
            PerIdAux: this.filtros.PerAux != null ? this.filtros.PerAux.PerId : null,
            InfFic: this.filtros.InfFic,
            InfAmbito: this.filtros.InfAmbito,
            InfCarpeta: this.filtros.InfCarpeta
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
              this.loading = false
              resolve({
                item
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al exportar los datos.');
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
          this.extraData = data.extraData;
          this.loadChartValues();
        });
      },
      loadChartValues() {
        // this.fixChartAspectRatio(false);
        this.fixEmptyDates();
        const datasets = [];
        datasets.push({
            label: 'Nº Visualizaciones',
            borderColor: '#FE8588',
            pointBackgroundColor: '#D93236',
            data: this.extraData.TotalByDay.map(m => m.Total)
        });
        datasets.push({
            label: 'Nº Visualizaciones internas',
            borderColor: '#84B1C5',
            pointBackgroundColor: '#0085FF',
            data: this.extraData.TotalByDay.map(m => m.TotalInt)
        });
        datasets.push({
            label: 'Nº Visualizaciones externas',
            borderColor: '#A7DAA8',
            pointBackgroundColor: '#5ADD7D',
            data: this.extraData.TotalByDay.map(m => m.TotalExt)
        });

        const labels = this.extraData.TotalByDay.map(m => {
          if(this.extraData.ChartType == 'YEAR'){
            return new Date(m.Fecha).getFullYear();
          }
          if(this.extraData.ChartType == 'MONTH'){
            const date = new Date(m.Fecha);
            return this.getMonthLabel(date.getMonth()) + ' ' + date.getFullYear();
          }
          return this.formatDate(m.Fecha, false);
        });

        // Centrar el resultado si solo hay un dato a mostrar
        this.chartOptions.scales.xAxes[0].offset = labels.length == 1;
        this.fixChartAspectRatio();
        
        this.chartData = {
            labels: labels,
            datasets: datasets
        }
      },
      fixEmptyDates() {
        if(this.extraData.TotalByDay.length == 0) { return; }
        const allDates = this.extraData.TotalByDay.map(m => new Date(m.Fecha));

        // Si no hay fecha de inicio/final será la del primer/último dato recibido 
        let firstDate = this.filtros.FechaDesde != null ? 
          new Date(this.filtros.FechaDesde + 'T00:00:00') : 
          new Date(allDates[0]);
        let lastDate = this.filtros.FechaHasta != null ? 
          new Date(this.filtros.FechaHasta + 'T00:00:00') : 
          new Date(allDates[allDates.length-1]);

        // Obviamos los meses y los días dependiendo de los datos a mostrar
        if(this.extraData.ChartType == 'YEAR') {
          firstDate.setMonth(0);
          lastDate.setMonth(0);
        }
        if(this.extraData.ChartType == 'YEAR' || this.extraData.ChartType == 'MONTH') {
          firstDate.setDate(1);
          lastDate.setDate(1);
        }

        // Añadimos las fechas sin datos que se encuentran dentro del rango elegido
        const datesToAdd = [];
        let currentDate = firstDate;
        
        do {
          if(allDates.map(m => m.getTime()).indexOf(currentDate.getTime()) == -1) {
            datesToAdd.push(currentDate);
          }
          currentDate = this.getNextDate(currentDate, this.extraData.ChartType);
        } while (currentDate <= lastDate)
        
        datesToAdd.forEach(date => {
          this.extraData.TotalByDay.push(
            {
              Fecha: date,
              Total: 0,
              TotalInt: 0,
              TotalExt: 0
            }
          )
        });
        this.extraData.TotalByDay.sort(function (a, b) {
          return new Date(a.Fecha).getTime() - new Date(b.Fecha).getTime();
        });
      },
      getNextDate(date, type) {
        let nextYear = date.getFullYear();
        if(type == 'YEAR') {
          nextYear++;
          return new Date(nextYear + '-01-01T00:00:00');
        }
        if(type == 'MONTH') {
          // +2 => +1 porque enero sería el mes 0 y +1 por el mes que tenemos que añadir
          let nextMonth = date.getMonth() + 2; 
          if(nextMonth > 12) {
            nextMonth = 1;
            nextYear++;
          }
          return new Date(nextYear + '-' + String(nextMonth).padStart(2, '0') + '-01T00:00:00');
        }

        return new Date(new Date(date).setDate(date.getDate() + 1));
      },
      onBtnDownloadReport(fileType){
        this.downloadReportFromApi(fileType)
        .then(data => {
            if(data.item === '') { 
            alert('Documento no encontrado');
            return; 
            }
            this.descargarDocumento(data.item.File, data.item.FileName);
        });
      },
      descargarDocumento(doc, docName){
        this.downloading = true;

        // Datos del fichero
        const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        // Descarga del fichero
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a'); 
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = docName; // Nombre del fichero
        a.click();
        a.remove();

        this.downloading = false;
      },
      fixChartAspectRatio(renderChart = true) {
          if(window.innerWidth < 600){
              this.chartOptions.aspectRatio = 1.1;
          }
          else if (window.innerWidth > 1264){
              this.chartOptions.aspectRatio = 2.15;
          }
          else{
              this.chartOptions.aspectRatio = 2;
          }
          
          if(renderChart && this.$refs.lineChart != null) {
            this.$refs.lineChart.renderChart(this.$refs.lineChart.chartData, this.$refs.lineChart.options);
          }
      },
      getMonthLabel(value) {
          const labelsMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          return labelsMeses[value];
      },
      formatDate (value, addTime = true) {
        const date = new Date(value);
        let dateString = String(date.getDate()).padStart(2, '0') + '/' + 
        String(date.getMonth() + 1).padStart(2, '0')  + '/' + 
        date.getFullYear();
        if(addTime) {
          dateString +=  ' ' +
            String(date.getHours()).padStart(2, '0') +  ':' + 
            String(date.getMinutes()).padStart(2, '0');
        }
        return dateString;
      }
    } 
})
</script>

<style scoped>
  .chart-container {
    flex-grow: 1;
    min-height: 0;
  }
  .chart-container > div {
    position: relative;
    height: 100%;
  }
</style>
