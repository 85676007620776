var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center"},[_vm._v(" Documentos de empresa ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-treeview',{staticClass:"ml-2",attrs:{"items":_vm.infs,"item-key":"InfCatId","item-text":"InfCatNom","item-children":"InfCatChildren","open-on-click":"","transition":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.FileType == null)?_c('v-icon',{attrs:{"color":"orange","large":""}},[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):(_vm.files[item.FileType] != null)?_c('v-icon',{attrs:{"color":_vm.filesColor[item.FileType],"large":""}},[_vm._v(" "+_vm._s(_vm.files[item.FileType])+" ")]):_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.files["unknown_ext"])+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.InfCatObs == 'true')?_c('a',{on:{"click":function($event){return _vm.viewItemClick(item)}}},[(item.FileType != null)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"blue-grey","large":""}},[_vm._v(" mdi-download-circle ")]):_vm._e()],1):_c('a',{on:{"click":function($event){return _vm.dowloadItemClick(item)}}},[(item.FileType != null)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"blue-grey","large":""}},[_vm._v(" mdi-download-circle ")]):_vm._e()],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"black--text text-body-2",on:{"click":function($event){return _vm.clickEvent(item)}}},[_vm._v(_vm._s(item.InfCatNom))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }